
import { httpGet, httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import { ajaxLoading, errorMessage, formatTime, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { computed, defineComponent, ref } from 'vue';
import useFormData from './useFundingFormData';
export default defineComponent({
  components: { FileContent },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    const sapcedata = ref('-');
    const { rules, formData, formRef, setFormData, fundingOption, valInteger, valRate, validDays, valFundingExchange, contractUrlsArray, changeBusinessType } =
      useFormData(params);
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.licenseAttachment = {
          fundingBaseFileId: null,
          fundingBaseFileName: fileList[0].name,
          fundingBaseFileUrl: fileList[0].url,
        };
      } else {
        formData.licenseAttachment = null;
      }
      formRef.value.validate('licenseAttachment.fundingBaseFileUrl');
    };
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const getFundingOption = async (name: string) => {
      const params = {
        fundingShortName: name,
      };
      const res = await httpPost('/malicrm/funding/queryByShortName', params);
      if (res.code === 200) {
        fundingOption.value = [];
        fundingOption.value = res.result.map((item) => {
          return { shortName: item.shortName, id: item.id };
        });
      } else {
        errorMessage(res.message);
      }
    };
    const initFundingOption = () => {
      // 失去焦点时重置筛选条件
      if (fundingOption.value && (fundingOption.value as any).length <= 0) {
        getFundingOption('');
      }
    };
    const getFundingId = (val) => {
      (fundingOption.value as any).forEach((item) => {
        if (item.id == val) {
          // eslint-disable-line
          console.log('item', item.shortName);
          console.log('formData', formData.shortName);
        }
      });
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };

      ajaxLoading.lock();
      const res = await httpPost('malicrm/funding/saveFundingContract', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        if (props.formType === 1) {
          successMessage('保存成功');
        }
        if (props.formType === 2) {
          successMessage('编辑成功');
        }
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          if (validDays()) {
            formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD');
            formData.startDate = formatTime(formData.startDate, 'YYYY-MM-DD');
            formData.endDate = formatTime(formData.endDate, 'YYYY-MM-DD');
            submitData();
          }
        } else {
          return false;
        }
      });
    };
    const initFile = () => {
      if (formData.licenseAttachment && formData.licenseAttachment.fundingBaseFileUrl) {
        (contractUrlsArray.value as any).push({
          name: formData.licenseAttachment.fundingBaseFileName,
          url: formData.licenseAttachment.fundingBaseFileUrl,
        });
      }
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/funding/getFundingContractById/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        initFile();
      } else {
        errorMessage(res.message);
      }
    };
    if (props.formType > 1) {
      initData((props.data as any).value.id);
    }
    getFundingOption('');
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      getFileUrls,
      getFundingOption,
      fundingOption,
      initFundingOption,
      getFundingId,
      valInteger,
      valRate,
      validDays,
      valFundingExchange,
      initData,
      initFile,
      contractUrlsArray,
      changeBusinessType,
      readOnly,
      sapcedata,
    };
  },
});
