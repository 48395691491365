
import { defineComponent, ref, getCurrentInstance } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import FundingForm from './components/FundingForm.vue';
import ViewFundingForm from './components/ViewFundingForm.vue';
import useListData from './components/usefunFrameworkListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'FunFrameworkList',
  components: { CrmList, FundingForm, ViewFundingForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType, setFormType } = useVisible();
    const rowData = ref({});
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const rowClick = (row) => {
      console.log('row click', row);
    };
    // 打开新增弹窗
    const openDrawer = (formType: number): void => {
      setFormType(formType);
      showVisible();
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      rowClick,
      getLoadPage,
      rowData,
      openDrawer,
    };
  },
});
