<template>
  <CrmFormLayout title="查看合同">
    <el-form ref="form" label-position="left" label-width="120px" :show-message="true" :model="formData" :rules="formType != 3 ? rules : {}">
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contractNo">
              <el-input
                v-model="formData.contractNo"
                clearable
                :disabled="readOnly"
                :maxlength="30"
                class="mali-full__inputcom"
                placeholder="请输入合同编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约时间">
              <el-date-picker
                v-if="formData.signDate || !readOnly"
                v-model="formData.signDate"
                type="date"
                prefix-icon="null"
                placeholder="请选择签约时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="资金方" prop="fundingId">
              <el-select
                :disabled="readOnly"
                v-model="formData.fundingId"
                class="mali-full__input"
                filterable
                remote
                :remote-method="getFundingOption"
                @blur="initFundingOption"
                @change="getFundingId"
                placeholder="请选资金方"
              >
                <el-option v-for="item in fundingOption" :key="item.id" :label="item.shortName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务类型" prop="businessType">
              <el-select
                :disabled="readOnly"
                v-model="formData.businessType"
                @change="changeBusinessType"
                class="mali-full__input"
                :class="{ 'no-select-arrow': readOnly }"
                placeholder="请选择业务类型"
              >
                <el-option :label="item.label" :value="item.value" v-for="(item, index) in filterOption.contract_business_type" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="生效时间" prop="startDate">
              <el-date-picker
                v-model="formData.startDate"
                v-if="formData.startDate || !readOnly"
                type="date"
                prefix-icon="null"
                placeholder="请选择生效时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期时间" prop="endDate">
              <el-date-picker
                v-model="formData.endDate"
                v-if="!readOnly || formData.endDate"
                type="date"
                prefix-icon="null"
                placeholder="请选择到期时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="预付比例(%)" prop="paymentRatio">
              <el-input
                :disabled="readOnly"
                v-model="formData.paymentRatio"
                clearable
                :maxlength="2"
                class="mali-full__inputcom"
                @blur="valInteger($event, 'paymentRatio')"
                placeholder="请输入预付比例(%)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="利率(年化%)" prop="interestRate">
              <el-input
                v-model="formData.interestRate"
                clearable
                :disabled="readOnly"
                :maxlength="5"
                class="mali-full__inputcom"
                @change="valRate($event, 'interestRate')"
                placeholder="请输入利率(年化%)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="最小使用天数" prop="minUseDays">
              <el-input
                :disabled="readOnly"
                v-model="formData.minUseDays"
                clearable
                :maxlength="2"
                class="mali-full__inputcom"
                @blur="valInteger($event, 'minUseDays')"
                placeholder="请输入最小使用天数"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最大使用天数" prop="maxUseDays">
              <el-input
                :disabled="readOnly"
                v-model="formData.maxUseDays"
                clearable
                :maxlength="3"
                class="mali-full__inputcom"
                @blur="valInteger($event, 'maxUseDays')"
                placeholder="请输入最大使用天数"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12" v-if="formData.businessType == 1 || !formData.businessType">
            <el-form-item label="指定汇率" prop="fundingExchange">
              <el-input
                v-if="formData.fundingExchange || !readOnly"
                v-model="formData.fundingExchange"
                clearable
                :maxlength="7"
                :disabled="readOnly"
                class="mali-full__inputcom"
                @change="valFundingExchange($event, 'fundingExchange')"
                placeholder="请输入指定汇率"
              ></el-input>
              <el-input v-else v-model="sapcedata" :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formData.businessType === 2">
            <el-form-item label="指定汇率" prop>
              <el-input
                v-if="!readOnly"
                v-model="formData.fundingExchange"
                clearable
                :maxlength="7"
                :disabled="formData.businessType === 2"
                class="mali-full__inputcom"
                :class="{ 'edit-no': formData.businessType === 2 }"
              ></el-input>
              <el-input v-else v-model="sapcedata" :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="formData.licenseAttachment && formData.licenseAttachment.fundingBaseFileUrl">
        <div class="mali-edit__title" style="margin-top: 20px">框架合同文件</div>
        <div class="mali-edit__form" style="padding: 30px 20px">
          <FileContent
            :readOnly="readOnly"
            :fromType="formType"
            :fileType="fileType"
            :max="1"
            :fileList="contractUrlsArray"
            @getFileUrls="getContractUrls"
            @isMassge="isMassge"
          >
          </FileContent>
        </div>
      </div>
      <div class="mali-edit__form" style="margin-top: 20px" v-if="formType < 3 || (formType == 3 && formData.remark)">
        <div style="padding: 0 0 18px 0">合同备注</div>
        <div style="padding: 0 0 20px 0">
          <el-input
            v-if="formData.remark || !readOnly"
            v-model="formData.remark"
            :maxlength="300"
            :disabled="readOnly"
            class="mali-full__input h60"
            placeholder="请输入合同备注"
            type="textarea"
            resize="none"
          ></el-input>
          <el-input v-else v-model="sapcedata" :disabled="readOnly" class="mali-full__input"></el-input>
        </div>
      </div>
    </el-form>
    <div class="crm-button__group" v-if="formType >= 3">
      <button class="crm-form__button primary" @click="close(false)">关闭</button>
    </div>
  </CrmFormLayout>
</template>

<script>
import FileContent from '@/components/FileContent/FileContent.vue';
import { store } from '@/store';
export default {
  components: { FileContent },
  props: {
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看
      type: [String, Number],
      default: 1,
    },
    id: {
      type: [String, Number],
      default: '',
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sapcedata: '-',
      formData: {
        contractNo: null, // 合同编号
        signDate: null, // 签约时间
        startDate: null, // 生效时间
        endDate: null, // 到期时间
        paymentRatio: null, // 预付比例(%)
        interestRate: null, // 利率
        minUseDays: null, // 最小使用天数
        maxUseDays: null, // 最大使用天数
        remark: null, // 合同备注
        licenseAttachment: {
          fundingBaseFileId: null, // 文件Id
          fundingBaseFileName: null, // 文件名称
          fundingBaseFileUrl: null, // 文件地址
        },
        fundingExchange: null, // 指定利率
        id: null,
        fundingId: null,
        shortName: null,
      },
      fundingOption: [], // 选择资金方Option
      contractUrlsArray: [], // 框架合同文件
      rules: {
        contractNo: [{ required: true, message: '请输入合同编号', trigger: 'blur' }], // 合同编号
        startDate: [{ required: true, message: '请选择生效时间', trigger: 'blur' }], // 生效时间
        endDate: [{ required: true, message: '请选择到期时间', trigger: 'blur' }], // 到期时间
        paymentRatio: [{ required: true, message: '请输入预付比例(%)', trigger: 'blur' }], // 预付比例
        interestRate: [{ required: true, message: '请输入利率(年化%)', trigger: 'blur' }], // 利率
        fundingExchange: [{ required: true, message: '请输入指定汇率', trigger: 'blur' }], // 利率
        minUseDays: [{ required: true, message: '请输入最小使用天数', trigger: 'blur' }], // 最小使用天数
        maxUseDays: [{ required: true, message: '请输入最大使用天数', trigger: 'blur' }], // 最大使用天数
        'licenseAttachment.fundingBaseFileUrl': [{ required: true, message: '请上传框架合同文件', trigger: 'change' }],
        businessType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        fundingId: [{ required: true, message: '请选择资金方', trigger: 'change' }],
      },
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .doc, .xls, .xlsx, .zip',
      fromType: 1, // 组件展示类型,
      fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx,zip   大小上限：30M',
      businessType: null, // 业务类型
      filterOption: {
        contract_business_type: store.state.dict.options.contract_business_type,
      },
    };
  },
  computed: {
    readOnly() {
      return this.formType > 2;
    },
  },
  created() {
    if (this.formType > 1) {
      this.getinitData(this.id);
    }
    this.getFundingOption('');
  },
  methods: {
    isMassge() {
      // 点击添加文件时禁用保存按钮
      this.disabled = true;
    },
    close() {
      this.$emit('close', false);
    },
    cancelSubmit() {
      this.$emit('cancel', false);
    },
    changeBusinessType(val) {
      // 业务类型为期货 1，此项可以编辑 指定汇率; 如果业务类型为现货 2，此项默认为空且不可编辑
      if (val === 2) {
        this.formData.fundingExchange = null;
        this.$refs.form.clearValidate(['fundingExchange']);
      }
      if (val === 1) {
        this.$refs.form.validateField(['fundingExchange']);
      }
    },
    getFundingId(val) {
      this.fundingOption.forEach((item) => {
        if (item.id === val) {
          // eslint-disable-line

          this.formData.shortName = item.shortName;
          this.formData.fundingId = item.id;
        }
      });
    },
    initFundingOption() {
      // 失去焦点时重置筛选条件
      if (this.fundingOption && this.fundingOption.length <= 0) {
        this.getFundingOption('');
      }
    },
    /*
     * 获取资金方筛选条件option
     * params@name 用户输入值
     */
    getFundingOption(name) {
      const url = '/malicrm/funding/queryByShortName';
      const data = { fundingShortName: name };
      this.ajax({
        type: 'POST',
        url,
        data,
        success: (res) => {
          if (res.code === 200) {
            this.fundingOption = [];
            this.fundingOption = res.result.map((item) => {
              return { shortName: item.shortName, id: item.id };
            });
          }
        },
      });
    },
    // 校验只能输入整数
    valInteger(e, prop) {
      let value = e.target.value;
      value = value.replace(/^(0+)|[^\d]+/g, '');
      if (value.length > 0 && parseFloat(value) > 0) {
        this.formData[prop] = parseInt(value);
      } else {
        this.formData[prop] = null;
      }
    },
    valRate(val, prop) {
      let value = val;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99.99) {
        value = 99.99;
      }
      if (parseFloat(value) <= 99.99 && parseFloat(value) > 0) {
        this.formData[prop] = parseFloat(parseFloat(value).toFixed(2));
      } else {
        this.formData[prop] = null;
      }
    },
    valFundingExchange(val, prop) {
      let value = val;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入四个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99.9999) {
        value = 99.9999;
      }
      if (parseFloat(value) <= 99.9999 && parseFloat(value) > 0) {
        this.formData[prop] = parseFloat(parseFloat(value).toFixed(4));
        if (this.formData[prop] === 0) {
          this.formData[prop] = null;
        }
      } else {
        this.formData[prop] = null;
      }
    },
    // 获取上传的 框架合同文件
    getContractUrls(titleImg, fileNameArray, fileUrlArray) {
      if (fileNameArray[0] && fileUrlArray[0]) {
        this.formData.licenseAttachment.fundingBaseFileName = fileNameArray[0];
        this.formData.licenseAttachment.fundingBaseFileUrl = fileUrlArray[0];
      } else {
        this.formData.licenseAttachment.fundingBaseFileName = null;
        this.formData.licenseAttachment.fundingBaseFileUrl = null;
      }
      this.$refs.form.validateField(['licenseAttachment.fundingBaseFileUrl']);
    },
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.minUseDays - this.formData.maxUseDays >= 0) {
            this.errorTip('最小使用天数应小于最大使用天数');
          } else if (this.formData.businessType === 1 && !this.formData.fundingExchange) {
            this.errorTip('请填写所有带星号的必填项');
          } else {
            this.submitForm();
          }
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
    },
    getinitData(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/funding/getFundingContractById/' + id,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.initFile();
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 初始化 文件展示
    initFile() {
      if (this.formData.licenseAttachment && this.formData.licenseAttachment.fundingBaseFileUrl) {
        this.contractUrlsArray.push({
          name: this.formData.licenseAttachment.fundingBaseFileName,
          url: this.formData.licenseAttachment.fundingBaseFileUrl,
        });
      }
    },
    submitForm() {
      const params = Object.assign({}, this.formData);
      this.ajax({
        type: 'POST',
        url: 'malicrm/funding/saveFundingContract',
        data: params,
        success: (res) => {
          if (res.code === 200) {
            if (this.formType === 1) {
              this.successTip('保存成功', 3000);
            }
            if (this.formType === 2) {
              this.successTip('编辑成功', 3000);
            }
            this.$emit('cancel', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
