import { errorMessage } from '@/utils/publicMethods';
import { reactive, ref, toRefs } from 'vue';
type FormData = {
  contractNo: string | null; // 合同编号
  businessType: string | number | string; // 业务类型
  signDate: string | null; // 签约时间
  startDate: string | null; // 生效时间
  endDate: string | null; // 到期时间
  paymentRatio: number | string | null; // 预付比例(%)
  interestRate: number | string | null; // 利率
  minUseDays: number | string | null; // 最小使用天数
  maxUseDays: number | string | null; // 最大使用天数
  remark: number | string | null; // 合同备注
  licenseAttachment: any;
  fundingExchange: number | string | null; // 指定利率
  id: number | string;
  version: number | string;
  fundingId: number | string;
  shortName: number | string;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用

  const formData: FormData = reactive({
    contractNo: null, // 合同编号
    businessType: null, // 合同编号
    signDate: null, // 签约时间
    startDate: null, // 生效时间
    endDate: null, // 到期时间
    paymentRatio: null, // 预付比例(%)
    interestRate: null, // 利率
    minUseDays: null, // 最小使用天数
    maxUseDays: null, // 最大使用天数
    remark: null, // 合同备注
    licenseAttachment: {
      fundingBaseFileId: null, // 文件Id
      fundingBaseFileName: null, // 文件名称
      fundingBaseFileUrl: null, // 文件地址
    },
    fundingExchange: null, // 指定利率
    id: null,
    version: null,
    fundingId: null,
    shortName: null,
    ...params,
  });
  const valInteger = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/^(0+)|[^\d]+/g, '');
    if (value.length > 0 && parseFloat(value) > 0) {
      formData[prop] = parseInt(value);
    } else {
      formData[prop] = null;
    }
  };
  const valRate = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两个小数
    value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) === 0) {
        value = value.substr(1);
      }
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    if (value > 99.99) {
      value = 99.99;
    }
    if (parseFloat(value) <= 99.99 && parseFloat(value) > 0) {
      formData[prop] = parseFloat(parseFloat(value).toFixed(2));
    } else {
      formData[prop] = null;
    }
  };
  const valFundingExchange = (val, prop) => {
    console.log('val', val);
    let value = val;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入四个小数
    value = value.replace(/^(-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3"); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) === 0) {
        value = value.substr(1);
      }
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    if (value > 99.9999) {
      value = 99.9999;
    }
    if (parseFloat(value) <= 99.9999 && parseFloat(value) > 0) {
      formData[prop] = parseFloat(parseFloat(value).toFixed(4));
      if (formData[prop] === 0) {
        formData[prop] = null;
      }
    } else {
      formData[prop] = null;
    }
  };
  const validDays = () => {
    if ((formData as any).minUseDays - (formData as any).maxUseDays >= 0) {
      errorMessage('最小使用天数应小于最大使用天数');
      return false;
    } else if (formData.businessType === 1 && !formData.fundingExchange) {
      errorMessage('请填写所有带星号的必填项');
      return false;
    } else {
      console.log('333');
      return true;
    }
  };
  // 业务类型选择事件
  const changeBusinessType = (val) => {
    if (val === 2) {
      formData.fundingExchange = null;
      formRef.value.clearValidate(['fundingExchange']);
    }
    if (val === 1) {
      formRef.value.validateField(['fundingExchange']);
    }
  };
  const customFormData = reactive({
    fundingOption: [], // 选择资金方Option
    contractUrlsArray: [], // 框架合同文件
    fileType:
      '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .doc, .xls, .xlsx, .zip',
    fileTips:
      '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx,zip   大小上限：30M',
  });
  const rules = reactive({
    contractNo: [
      { required: true, message: '请输入合同编号', trigger: 'blur' },
    ], // 合同编号
    startDate: [{ required: true, message: '请选择生效时间', trigger: 'blur' }], // 生效时间
    endDate: [{ required: true, message: '请选择到期时间', trigger: 'blur' }], // 到期时间
    paymentRatio: [
      { required: true, message: '请输入预付比例(%)', trigger: 'blur' },
    ], // 预付比例
    interestRate: [
      { required: true, message: '请输入利率(年化%)', trigger: 'blur' },
    ], // 利率
    fundingExchange: [
      { required: true, message: '请输入指定汇率', trigger: 'blur' },
    ], // 利率
    minUseDays: [
      { required: true, message: '请输入最小使用天数', trigger: 'blur' },
    ], // 最小使用天数
    maxUseDays: [
      { required: true, message: '请输入最大使用天数', trigger: 'blur' },
    ], // 最大使用天数
    'licenseAttachment.fundingBaseFileUrl': [
      { required: true, message: '请上传框架合同文件', trigger: 'change' },
    ],
    businessType: [
      { required: true, message: '请选择业务类型', trigger: 'change' },
    ],
    fundingId: [{ required: true, message: '请选择资金方', trigger: 'change' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    valInteger,
    valRate,
    valFundingExchange,
    validDays,
    changeBusinessType,
  };
};
