import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import {
  erpConfirm,
  errorMessage,
  showFiles,
  successMessage,
} from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'contractNo',
      value: '',
      placeholder: '请输入资方框架合同编号',
      width: 200,
    },
    {
      type: 'input',
      prop: 'shortName',
      value: '',
      placeholder: '请输入所属资金方',
    },
    {
      type: 'signSelect',
      prop: 'businessType',
      value: '',
      options: 'contract_business_type',
      placeholder: '请选择业务类型',
    },
    {
      type: 'input',
      prop: 'name',
      value: '',
      placeholder: '请输入文件名',
    },
    {
      type: 'time',
      prop: ['endStartDate', 'endEndDate'],
      value: [],
      placeholder: ['到期开始时间', '到期结束时间'],
    },
    {
      type: 'input',
      prop: 'creator',
      value: '',
      placeholder: '请输入创建人',
    },
    {
      type: 'time',
      prop: ['createdStartTime', 'createdEndTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '资方框架合同编号',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.contractNo}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 查看 */
          const clickFn = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(3);
            Instance.setupState.showVisible();
          };
          return { clickFn };
        },
      }),
      prop: 'contractNo',
      minWidth: 120,
    },
    {
      label: '所属资金方',
      prop: 'shortName',
      minWidth: 120,
    },
    {
      label: '业务类型',
      prop: 'businessTypeDisplay',
      minWidth: 70,
    },
    {
      label: '文件名',
      prop: 'name',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.name}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const clickFn = (row) => {
            showFiles(row.name, row.url);
          };
          return { clickFn };
        },
      }),
      minWidth: 120,
    },
    {
      label: '到期时间',
      prop: 'endDate',
      component: defineComponent({
        template: `
          <div  :style="getFontColor(row)">
              {{row.endDate}}
          </div>
        `,
        props: ['row'],
        setup() {
          const getFontColor = (row) => {
            const res = '-';
            if (row && row.markRed === true) {
              return 'color:#E93939';
            }
            return res;
          };
          return { getFontColor };
        },
      }),
      minWidth: 120,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 80,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 130,
    },
    {
      label: '更新人',
      prop: 'lastOperator',
      minWidth: 90,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-if="hasPermission('crm:funding:saveFundingContract')" type="primary" plain @click="edit(row)">编辑</el-button>
            <el-button v-if="hasPermission('crm:funding:deleteFundingContract')" type="info" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除吗？')
              .then(async () => {
                const res = await httpPost(
                  '/malicrm/funding/deleteFundingContract',
                  {
                    id: row.id,
                    version: row.version,
                  }
                );
                if (res.code === 200) {
                  successMessage('删除成功');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      width: 200,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
